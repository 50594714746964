






































import AddressForm from '@/components/forms/AddressForm.vue';
import PatientAddressForm from '../components/forms/PatientAddressForm.vue';
import PatientDetailsForm from '../components/forms/PatientDetailsForm.vue';
import PatientLocationInput from '@/components/inputs/PatientLocationInput.vue';
import PatientPrimaryDetails from '../components/PatientPrimaryDetails.vue';
import { Patient, PatientData } from '@/models';
import { defineComponent, ref } from '@vue/composition-api';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'PatientsCreatePage',
  components: { PatientPrimaryDetails, PatientDetailsForm, AddressForm, PatientAddressForm, PatientLocationInput },
  setup() {
    const { router } = useRouter();

    const step = ref(1);

    const next = (): void => {
      step.value++;
    };

    const back = () => {
      step.value--;
    };

    const finish = () => {
      router.push({ name: routeNames.practitioner.patients.view.profile, params: { uuid: patient.value.uuid } });
    };

    const patient = ref(
      new Patient({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        gender: '',
      } as PatientData)
    );

    async function createPatient(): Promise<void> {
      const response = await patient.value?.create();
      patient.value = response;
      next();
    }

    return {
      step,
      patient,
      next,
      back,
      createPatient,
      finish,
    };
  },
});
